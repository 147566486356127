import React, { useEffect, useState, useRef } from "react";
import "./OurProducts.scss";
import { Banner } from "../../components/Banner/Banner";
import { Link, useLocation } from "react-router-dom";
import {
  downdropdownarrow,
  ourproductbanner,
  ourproductbannermob,
  reset,
  search,
} from "../../images";
import { reduceDescSize } from "../../helpers/utils";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";
import config from "../../config/config";
import http from "../../helpers/http";
import Sticky from "../../components/Sticky/Sticky";
import KnowMoreCta from "../../components/KnowMoreCta/KnowMoreCta";
const OurProducts = (props) => {
  const { pathname } = useLocation();
  const { width } = useWindowDimensions();
  const [productIndex, setProductIndex] = useState(0);
  const [categories, setCategories] = useState([]);

  const [datas, setDatas] = useState([]);
  const [loading, setloading] = useState(false);
  const [loadingmore, setloadingmore] = useState(false);
  const [loadMoreVisible, setLoadMoreVisible] = useState(true);
  const [dataCount, setdataCount] = useState(12);
  const [isCategoryVisible, setisCategoryVisible] = useState(true);
  const [currentCategoryName, setCurrentCategoryName] = useState("");
  // const navigate = useNavigate();
  // const base_url = config.api_url + "products"; //without trailing slash
  const image_url = config.image_url + config.image_path.product; //with trailing slash

  const searchRef = useRef(null);

  //logical functions
  const handleCategoryTabChange = (e) => {
    searchRef.current.value = "";
    const category = e.target.value;
    const selectedCategory = categories.find((cat) => cat.slug === category);
    if (selectedCategory) {
      updateCategoryAndBreadcrumb(selectedCategory.name);
    }
    props.history.push("/our-products/" + category);
  };

  const updateCategoryAndBreadcrumb = (name) => {
    setCurrentCategoryName(name);
  };

  const handleSearch = (e) => {
    console.log("search ", e.target.value);
    let search = e.target.value;
    if (search.length) {
      setisCategoryVisible(false);
    } else {
      setisCategoryVisible(true);
    }
    fetchDatas(props.match.params.category, 0, search);
  };

  const handleLoadMore = () => {
    console.log("load more ");
    setloadingmore(true);
    fetchDatas(props.match.params.category, dataCount);
    setdataCount(dataCount + 12);
    setloadingmore(false);
  };

  const fetchDatas = async (categorySlug, offset = 0, search = null) => {
    let url = `${config.api_url}products/categories/${categorySlug}`;
    if (offset) {
      url = url + `?offset=${offset}`;
    }

    if (search) {
      url = url + `?search=${search}`;
    }

    const { data } = await http.get(url);
    if (data) {
      if (offset) {
        setDatas((prevState) => {
          return [...prevState, ...data];
        });
      } else {
        setDatas(data);
      }

      if (!search) {
        if (data.length < 12) {
          setLoadMoreVisible(false);
        } else {
          setLoadMoreVisible(true);
        }
      } else {
        setLoadMoreVisible(false);
      }
    }
  };

  const fetchCategory = async () => {
    const data = await http.get(`${config.api_url}products/categories`);
    if (data) {
      const currentCategory = data.data.find(
        (cat) => cat.slug === props.match.params.category
      );
      setCurrentCategoryName(currentCategory?.name);
      setCategories(data.data);
    }
  };

  const categoryList = categories.length
    ? categories.map((item, i) => {
        return (
          <span
            className={`category_btn ${
              props.match.params.category === item.slug ? "active" : ""
            }`}
            onClick={() => {
              searchRef.current.value = "";
              updateCategoryAndBreadcrumb(item.name);
              props.history.push("/our-products/" + item.slug);
            }}
            key={i}
          >
            {item.name}
          </span>
        );
      })
    : null;

  const productsList = datas.length
    ? datas.map((item, i) => {
        const categoryName =
          categories.find((cat) => cat.slug === props.match.params.category)
            ?.name || currentCategoryName;
        return (
          <div
            key={i}
            onClick={() => setProductIndex(i)}
            className={`col-md-6 col-lg-3 product_details_container  ${
              productIndex === i ? "active" : ""
            } `}
          >
            <div className="product_detail_wrapper" key={i}>
              <Link
                to={{
                  pathname: `/our-products/${item.productCategory.slug}/${item.slug}`,
                  state: { categoryName: item.productCategory.name },
                }}
              >
                <div className="imageanimate zoom-out">
                  <img
                    width="375"
                    height="352.55"
                    src={image_url + item.thumbnail}
                    alt="caterina our product img"
                    loading="lazy"
                    className="product_img"
                  />
                  <div className="content-overlay"></div>
                </div>
                <div className="text_container">
                  <div className="text_wrapper">
                    <h2 className="product_category">
                      {item.productCategory.name}
                    </h2>
                    <h2 className="product_name">{item.name.toLowerCase()}</h2>
                    <p
                      className="product_synopsis"
                      dangerouslySetInnerHTML={{
                        __html: reduceDescSize(item.description, 75).replace(
                          /(<? *script)/gi,
                          "illegalscript"
                        ),
                      }}
                    ></p>
                  </div>
                  <div className="product_cta_wrapper">
                    <div className="common_know_more">
                      Read more
                      <span className="arrow_image"></span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        );
      })
    : null;

  const mbFloorTabList = categories.length
    ? categories.map((item, i) => (
        <option
          className="dropdown_option"
          value={item.slug}
          key={i}
          data-categorytabindexid={i}
        >
          {item.name}
        </option>
      ))
    : null;

  useEffect(() => {
    setdataCount(12);
    setloading(true);
    fetchDatas(props.match.params.category);
    fetchCategory();
    setloading(false);
    window.scrollTo(0, 0);
  }, [props.match.params.category]);

  if (loading) {
    return "Loading";
  }
  return (
    <>
      <MetaDecorator
        metaTitle={datas[0]?.productCategory?.meta_title}
        metaDesc={datas[0]?.productCategory?.meta_desc}
        canonicalLink={window.location.href}
      />
      {/* <MetaDecorator
        metaTitle="Caterina Commercial Cooking &amp; Cleaning Appliances Kenya"
        metaDesc="Caterina Commercial Cooking &amp; Cleaning Appliances Kenya"
        canonicalLink={window.location.href}
      /> */}
      <Banner
        imgSrc={width > 767 ? ourproductbanner : ourproductbannermob}
        breadcrumbWord1="Home"
        breadcrumbWord4={currentCategoryName || ""}
        disabled
        h1={true}
      />

      {/* {isCategoryVisible && } */}

      <section className="ourpro_sec1" data-aos="fade-up">
        <h2 className="hollow_title">Products</h2>
        <div className="my_container">
          <div className="search_sec">
            <div className="search_form">
              <input
                ref={searchRef}
                type="text"
                className="search_input"
                placeholder="type your words here"
                onKeyUp={handleSearch}
              />
              {!isCategoryVisible && (
                <button className="reset_btn">
                  <img
                    src={reset}
                    alt="Reset Button"
                    className="reset_img"
                    onClick={() => {
                      searchRef.current.value = "";
                      setisCategoryVisible(true);
                      fetchDatas(props.match.params.category);
                    }}
                  />
                </button>
              )}

              <img
                src={search}
                alt="Indoline event search logo"
                className="search_icon"
              />
            </div>
          </div>
          {isCategoryVisible && (
            <>
              {width >= 540 ? (
                <div className="category_btns_wrapper">{categoryList}</div>
              ) : (
                <div className="dropdown_flex">
                  <div className="dropdown_text">Product Name</div>
                  <select
                    onChange={handleCategoryTabChange}
                    className="dropdown_select"
                    value={props.match.params.category}
                  >
                    {mbFloorTabList}
                  </select>
                  <img
                    src={downdropdownarrow}
                    alt="Caterina dropdown logo"
                    className="downdropdownarrow"
                  />
                </div>
              )}
            </>
          )}
        </div>
      </section>

      <section className="ourpro_sec2" data-aos="fade-up">
        <div className="my_container">
          <div className="row m-0">{productsList}</div>

          {loadMoreVisible ? (
            <>
              <div className="horizontal_line"></div>
              <div className="loadmore_cta_wrapper center">
                <div onClick={handleLoadMore} className="load_more_cta">
                  {loadingmore ? "Loading " : "Load More"}
                </div>
              </div>
            </>
          ) : null}
        </div>
        {/* <h2 className="hollow_title">Products</h2> */}
      </section>
      <Sticky />
    </>
  );
};

export default OurProducts;
